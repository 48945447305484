/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_AMASTY_AUTOMATIC_RELATED_PRODUCTS } from './AmastyAutomaticRelatedProducts.config';

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Store/AmastyAutomaticRelatedProducts/Action/updateAmastyAutomaticRelatedProducts */
export const updateAmastyAutomaticRelatedProducts = (state) => ({
    type: UPDATE_AMASTY_AUTOMATIC_RELATED_PRODUCTS,
    state
});
