/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

import BundlePacksQuery from './BundlePacks.query';
/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Query/PackList/Query */
export class PackListQuery {
    getQuery(currentPage) {
        return new Field('packList')
            .addArgument('currentPage', 'Int!', currentPage)
            .addFieldList(BundlePacksQuery.getBundlesFields());
    }
}

export default new PackListQuery();
