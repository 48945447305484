import { MenuQuery as SourceMenuQuery } from 'SourceQuery/Menu.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/MultiMenu/Query */
export class MultiMenuQuery extends SourceMenuQuery {
    getQuery({ identifier }) {
        return new Field('scandiwebMenus')
            .addArgument('identifier', 'String!', identifier)
            .addFieldList(this._getMenuFields())
            .setAlias('menus');
    }

    _getMenuFields() {
        return [
            ...super._getMenuFields(),
            'menu_type'
        ];
    }

    _getMenuItemFields() {
        return [
            ...super._getMenuItemFields(),
            'categoryImage',
            'categoryTitle',
            'item_category_id',
            'categoryDescription',
            'categoryChildrenCount',
            'shopAllImage',
            'cms_block_identifier',
            'parentCategoryId',
            'productCount'
        ];
    }
}

export default new MultiMenuQuery();
