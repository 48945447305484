import MultiMenuQuery from 'Query/MultiMenu.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { prepareQuery } from 'Util/Query';
import {
    executeGet
} from 'Util/Request';

import { updateMenuItems } from './Menu.action';

export const ONE_MONTH_IN_SECONDS = 2592000;

/** @namespace Scandipwa/Store/Menu/Dispatcher */
export class MenuDispatcher {
    async updateMenuData(dispatch) {
        const query = [MultiMenuQuery.getQuery(this._getMenuOptions())];

        try {
            const data = await executeGet(prepareQuery(query), 'Menu', ONE_MONTH_IN_SECONDS);
            const { menus } = data;

            dispatch(updateMenuItems(menus));
        } catch (error) {
            showNotification('error', __('Error fetching Menu!'), error);
        }
    }

    _getMenuOptions() {
        const { header_content: { header_menu } = {} } = window.contentConfiguration;

        return {
            identifier: header_menu || 'new-main-menu'
        };
    }
}

export default new MenuDispatcher();
