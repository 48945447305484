/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { ONE_MONTH_IN_SECONDS } from 'Store/Menu/Menu.dispatcher';
import { prepareQuery } from 'Util/Query';
import { executeGet, fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';

import {
    AMASTY_BLOCK_MAP,
    RELATED_CART,
    RELATED_CART_CACHE,
    RELATED_ONE_MINUTE_IN_SECONDS,
    RELATED_PRODUCT
} from '../../component/Related/Related.config';
import BundlePacksQuery from '../../query/BundlePacks.query';
import PackListQuery from '../../query/PackList.query';
import RelatedProductQuery from '../../query/RelatedProduct.query';
import ViewMutationQuery from '../../query/ViewMutation.query';
import { updateAmastyAutomaticRelatedProducts } from './AmastyAutomaticRelatedProducts.action';

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Store/AmastyAutomaticRelatedProducts/Dispatcher */
export class AmastyAutomaticRelatedProductsDispatcher {
    fetchPackList = async (currentPage = 1, dispatch) => {
        const query = [PackListQuery.getQuery(currentPage)];
        const {
            packList
        } = await executeGet(prepareQuery(query), 'PackList', ONE_MONTH_IN_SECONDS);

        const { totalPages } = packList[0] || {};

        dispatch(updateAmastyAutomaticRelatedProducts({
            packList,
            packListTotalPages: totalPages
        }));
    };

    fetchBundlePacks = async (productId, bundlePackMainId, dispatch) => {
        if (!productId || bundlePackMainId === productId) {
            return;
        }

        await dispatch(updateAmastyAutomaticRelatedProducts({ bundlePackMainId: productId }));

        const query = [BundlePacksQuery.getQuery(productId)];
        const {
            bundles
        } = await executeGet(prepareQuery(query), 'AmastyBundlePacks', ONE_MONTH_IN_SECONDS);

        await dispatch(updateAmastyAutomaticRelatedProducts({ bundlePacks: bundles }));
    };

    fetchRelatedProducts = async (itemId, relatedPosition, loginState, dispatch) => {
        const {
            relatedMainId,
            previousLoginState,
            relatedCache,
            cachedItems
        } = getStore().getState().AmastyAutomaticRelatedProductsReducer;

        if (!itemId || (relatedMainId === itemId && previousLoginState === loginState)) {
            return;
        }

        const time = (new Date().getTime()) / RELATED_CART_CACHE;
        const cacheTime = relatedCache[itemId] ?? 0;

        // vvv Prevent from fetching multiple times
        if (
            time - cacheTime <= RELATED_ONE_MINUTE_IN_SECONDS
            && previousLoginState === loginState
        ) {
            if (relatedMainId !== itemId) {
                dispatch(updateAmastyAutomaticRelatedProducts({
                    relatedMainId: itemId,
                    relatedProducts: cachedItems[itemId] ?? []
                }));
            }

            return;
        }

        dispatch(updateAmastyAutomaticRelatedProducts({
            relatedCache: { ...relatedCache, [itemId]: (new Date().getTime()) / RELATED_CART_CACHE },
            relatedMainId: itemId,
            previousLoginState: loginState
        }));

        const position = relatedPosition === RELATED_CART ? RELATED_PRODUCT : relatedPosition;
        const blockId = AMASTY_BLOCK_MAP[relatedPosition];

        const query = [RelatedProductQuery.getQuery(itemId, position)];
        const {
            related: {
                items
            }
        } = await executeGet(prepareQuery(query), 'AmastyRelated', ONE_MONTH_IN_SECONDS);

        // vvv Complete the set should be first
        items?.sort((a, b) => (a.name > b.name ? 0 : -1));

        dispatch(updateAmastyAutomaticRelatedProducts({
            cachedItems: { ...cachedItems, [itemId]: items },
            relatedProducts: items
        }));

        await fetchMutation(ViewMutationQuery.getQuery(blockId));
    };
}

export default new AmastyAutomaticRelatedProductsDispatcher();
